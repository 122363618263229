// Use This URL for testing
const URL = "lims.vaibhavinstrumentation.in";

export const BASE_URL = `https://${URL}/api/`;
export const DOMAIN = `https://${URL}`;
export const MAX_COLS = 30;
export const ID_BASE = 1;
export const SRF_ID_BASE = 1;

// TODO: need to move to DB
export const BANK_DETAILS = {
  "Bank Name": "State Bank OF India, CBD Belapur Navi Mumbai",
  "Bank A/c. No.": "39813124209",
  "IFSC Code": "SBIN0061520",
};

export const weightUncertaintySupportiveValues ={
  barometer: 0.3,
  tempIndicator:0.56,
  rhIndicator:2.97,
  weightBox:7950,
};

export const GST_NUMBER = "27DSVPK5381Q1ZH";

export const DISABLED_MODULE =["Courier Register","History Card","Expenses","Client Complaints","Client Feedback","Audit","Purchase"
,"DUC Master","Department","Request Form","CoordinatesAddress","Settings","Datasheet Template","Discipline","ClientsUser","Department Analytics","WCR Report","OfflineMode","Supplier Invoice","Training Resources","Assigned Duc","Inward Register","SRF_ULR_Register"]; 

export const DEVICE_TYPE = ["desktop", "tablet", "mobile"];

export const LATEST_HASH = "89e09b5893d3f6da022ade0dbd903a52d47c63c6"
